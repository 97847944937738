import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosService } from 'api/axiosService';
import { components } from 'openapi-types';

export type Profile = NonNullable<components['schemas']['GetAccountMeResponse']['result']>;

export const useProfile = () => {
  return useQuery({
    queryKey: ['profile'],
    queryFn: async () =>
      await AxiosService.get<components['schemas']['GetAccountMeResponse']>(`account/me`),
    staleTime: 5000,
    gcTime: 10,
  });
};

export const useLazyProfile = () => {
  return useMutation({
    mutationKey: ['profile'],
    mutationFn: async () =>
      await AxiosService.get<components['schemas']['GetAccountMeResponse']>(`account/me`),
  });
};
